import { calculateCommission } from "@/lib/utils";
import { HelpCircle } from "lucide-react";
import { Alert } from "./ui/alert";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";

type CommissionProps = {
  volume: number;
  price: number;
};

export function Commission({ volume, price }: CommissionProps) {
  const {
    total,
    commission,
    commission_percentage,
    effectivePrice,
    commissionPerLiter,
  } = calculateCommission(volume, price);

  return (
    <>
      <Alert variant="information" className="max-w-[350px]">
        <p>
          <strong>Commission:</strong> {commissionPerLiter}/L (
          {commission_percentage}%)
        </p>
        <p>
          <strong>Effective Price:</strong> {effectivePrice}/L
        </p>
        <HoverCard>
          <HoverCardTrigger>
            <div className="flex flex-row items-center">
              <p className="text-sm">How is this calculated?</p>{" "}
              <HelpCircle className="h-4 w-4 ml-2" />
            </div>
          </HoverCardTrigger>
          <HoverCardContent className="w-[325px]">
            <ul>
              <li className="inline-block">Liters ≤ 30 000 (4.00%)</li>
              <li className="inline-block">
                30 000 &lt; Liters ≤ 250 000 (3.50%)
              </li>
              <li>250 000 &lt; Liters ≤ 500 000 (3.00%)</li>
              <li>500 000 &lt; Liters ≤ 1 000 000 (2.50%)</li>
              <li>Liters &gt; 1 000 000 (2.00%)</li>
            </ul>
          </HoverCardContent>
        </HoverCard>
      </Alert>
    </>
  );
}
